#welcomeImg {
    height: 130vh;
    width: 100%;
}

.container {
    position: relative;
    font-family: Arial;
}

.welcomeContainer {
    position: relative;
    text-align: center;
}

.welcomeBlock {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    font-size: 30px;
    margin: 0 auto;
    width: 100%;
    color: #945c74;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.welcomeLine {
    position: absolute;
    top: 53%;
    left: 0;
    right: 0;
    width: 400px;
    color: #945c74;
    height: 10px;
    margin: 0 auto;
}

#imageSource {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 13px;
    margin: 0 auto;
    width: 50%;
    color: #945c74;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    top: 125vh;
}

#imageSource > a {
 color:#c491a2
}

#externalLinks {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 13px;
    margin: 0 auto;
    width: 50%;
    color: #c491a2;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    top: 118vh;
}




.ContactMe {
    padding-top: 10%;
    padding-bottom: 30%;
}

.linkStyle:hover {
    color: rgb(red, green, blue);
}

.linkStyle {
    text-decoration: none;
    color: inherit;
}

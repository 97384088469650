.imageSpacing  {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}


.projectSpacing {
    width: 75%;
    margin-Left: auto;
    margin-right: auto;
    padding-top: 2%;
    padding-bottom: 2%;
}


.projectBorder {
    border: 3px ridge silver;
    border-radius: 15px;
    padding: 5px;
}

.Projects {
    display: 'flex';
    flex-wrap: wrap;
}
.AboutMe {
    padding-bottom: 5%;
    padding-top: 3%;
    height: 100vh;
}

#pictureBorder {
    padding: 1% 12% 2% 12%;
    width: 95%;
    min-width: 300px;
}

#appHeader {
    text-align: center;
    font-size: 50px;
    color: #945c74;
}

#appSubHeader {
    text-align: center;
}

#headerStyle {
    padding-top: 1%;
    padding-bottom: 1%;
    background-color: #e2d9dc;
    width: 100%;
    min-height: 75px;
}

#nameStyle {
    font-size: 35px;
    color: #945c74;
    min-width: 385px;
    padding-left: 20%;
}

#externalLinks {
    padding-top:10px;
    padding-right: 20%;
    min-width: 300px;
}

@media screen and (max-width: 767px ) {
    #imageMinWidth {
        display: none;
    }
    #aboutMeMinWidth {
        width: 100%;
        float: right
    }
    #pictureBorder {
        margin-left:5%;
    }
}
.App {
  font-family: "Quicksand", sans-serif !important;
}

.sectionTitle {
  text-align: center;
  color:#945c74;
}

.sectionSubTitle {
  width: 50%;
  text-align: center;
  color: rgb(133, 133, 133);
  font-size: 18px;
  margin-left: 25%;
}

.horizontalLine {
  color: silver;
  background-color: silver;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 25px;
  opacity: 0.4;
  width: 100%;
}

.sectionLine {
  color: silver;
  background-color:silver;
  height: 1px;
}

.lightBackground {
  width: 100%;
  background-color: #f2ebe5;
}

.secondBackground {
  width: 100%;
  background-color: #e2d9dc;
}

.colorAccent {
  background-color: #945c74;
}

.containerStyle {
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  max-width: 1280px;
  min-width: 280px;
  padding: 4% 10%;
}

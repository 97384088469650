#notFound {
    height: 100vh;
}

.footerPositioning {
    position: fixed;
    bottom: 0;
    width: 100%
}

#largeFont {
    font-size: 75px;
}

#pageNotFound {
    font-size: 27px;
}

#alignText {
    text-align: center;
}

#anchorMessage {
    padding-left: 20%;

}

#errorMessage {
    padding-top: 30%;
    font-family: "Lucida Console", Courier, monospace;
}

.notFoundStyle {
    margin-right: auto;
    margin-left: auto;
    width: 80%;
    max-width: 1280px;
    min-width: 280px;
    padding: 1% 10%;
}
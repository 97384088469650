.companyName {
    font-weight: bold;
    padding-left: 15px;
}

.positionName {
    font-style: italic;
}

.boxBorder {
    border:3px ridge silver;
    border-radius: 15px;
    padding: 5px;
}

.experienceSpacing {
    padding-top: 5%;
}

.experienceContainer {
    padding: 25px;
}

ul {
    list-style: none;
 }
 
.bulletStyle > ul li:before {
    content: "•";
    font-size: 150%;
    padding-right: 5px;
 }

 .bulletStyle > ul li ul li:before {
    content: "○";
    font-size: 90%;
    padding-right: 5px;
 }

.navBar {
    position: sticky; 
    /* right: 8%; */
    top: 35%;
    display: flex;
    flex-direction: row;
    width:8%;
    padding-left: 85%;
    max-height:0;
}

@media screen and (max-width: 830px ) {
    .navBar {
        display: none;
    }
}

html {
    scroll-behavior: smooth;
}

a :hover { 
    color: #945c74;
    text-decoration: none;
}

.MuiTabs-flexContainer > a:hover { /* Take off everything before and including > if you want everything hovered over to be the same color */
    color: #945c74 !important;
}

.MuiTab-textColorInherit.Mui-selected { /* Current tab selected */ 
    opacity: 1;
    color: #945c74;  
}

.MuiTab-root {
    min-width: 120px !important; /* Override default width for tabs, 160 was too large */
  }
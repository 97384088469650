.headerStyle { /* Used to set the same height since height has multiple lines*/
    padding-bottom:10px;
    color: #945c74;
}

.SkillSet {
    max-width: 150px;
}

#skillsPadding {
    padding-bottom: 3%;
}

.alignSkills {
    padding-left: 10%;
}

.noBullets {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.skillTitle {
    width: 60%;
    display: inline-block;
}

.skill {
    float: right;
}

.listStyle {
    min-height: 30px;
   /* min-width: 310px; */
   width: 310px;
}